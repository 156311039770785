import React from "react"
import { Link } from "gatsby"

export default ({ pageContext: { collections } }) => {
  // const { GATSBY_API_HOST } = process.env
  const GATSBY_API_HOST = "https://hamzatanjicherkaoui.com/api"
  return (
    <>
      <meta
        httpEquiv="Content-Security-Policy"
        content="upgrade-insecure-requests"
      />
      <section>
        <div className="max-w-screen-xl px-4 py-8 mx-auto sm:py-12 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:items-stretch">
            <div className="grid bg-gray-100 rounded place-content-center relative">
              <img
                className="absolute  object-cover w-full h-full z-0"
                src="https://notioly.com/wp-content/uploads/2022/11/180.Library.png"
                style={{
                  filter: "brightness(0.97)",
                  opacity: "0.9",
                }}
                alt=""
              />
              <div className="max-w-md mx-auto text-center lg:text-left z-10  ">
                <header
                  className=" relative overflow-hidden px-12 py-2"
                  style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                >
                  <h2 className="text-xl font-bold text-gray-900 sm:text-3xl">
                    Tasty Book
                  </h2>

                  <p className="mt-4 text-gray-800 font-bold">
                    Feast Your Mind on Tasty Ebooks
                  </p>
                </header>

                <a
                  href="#"
                  className="inline-block px-12 py-3 mt-8 text-sm font-medium text-white transition bg-purple-600 border border-purple-600 rounded-3xl hover:shadow focus:outline-none focus:ring"
                >
                  Shop All
                </a>
              </div>
            </div>

            <div className="lg:col-span-2 lg:py-8">
              <ul className="grid grid-cols-2 gap-4">
                <li>
                  <a href="#" className="block group">
                    <img
                      src="https://beatpcos.com/wp-content/uploads/2022/01/The-Beat-PCOS-5-Ebook-Bundle.png"
                      alt=""
                      className="object-cover w-full rounded aspect-square"
                    />

                    <div className="mt-3">
                      <h3 className="font-medium text-gray-900 group-hover:underline group-hover:underline-offset-4">
                        BEAT PCOS 5 EBOOK BUNDLE
                      </h3>

                      <p className="mt-1 text-sm text-gray-700">$150</p>
                    </div>
                  </a>
                </li>

                <li>
                  <a href="#" className="block group">
                    <img
                      src="https://public-files.gumroad.com/uenmx4njxt6nxgwhp9fpwyot8ipl"
                      alt=""
                      className="object-cover w-full rounded aspect-square"
                    />

                    <div className="mt-3">
                      <h3 className="font-medium text-gray-900 group-hover:underline group-hover:underline-offset-4">
                        The Complete 30-Day Challenges Bundle
                      </h3>

                      <p className="mt-1 text-sm text-gray-700">$150</p>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section>
        {collections &&
          collections.map(({ name, description, books }) => (
            <div className="max-w-screen-xl px-4 py-8 mx-auto sm:px-6 sm:py-12 lg:px-8">
              <header className="text-center">
                <h2 className="text-xl font-bold text-gray-900 sm:text-3xl">
                  {name}
                </h2>

                <p className="max-w-md mx-auto mt-4 text-gray-500">
                  {description}
                </p>
              </header>

              <ul className="grid gap-4 mt-8 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                {books.map(({ name: bookName, _id: id, image }) => (
                  <li className="mt-10">
                    <Link
                      to={`/book/${id}`}
                      className="block group mt-12 flex flex-col h-full  shadow-sm rounded-lg p-4 bg-white justify-items-center flex-no-wrap flex-stretch"
                    >
                      <img
                        src={`${GATSBY_API_HOST}/download/${image}`}
                        alt=""
                        className="max-w-sm rounded  bg-white p-1  h-96 object-cover w-full   "
                        style={{ width: "100%" }}
                      />

                      <div className="relative pt-3 bg-white h-full ">
                        <h3 className="text-xs text-gray-700 group-hover:underline group-hover:underline-offset-4 font-bold text-purple-900">
                          {bookName}
                        </h3>
                      </div>

                      <Link
                        to={`/book/${id}`}
                        className="bg-white text-purple-600 hover:bg-purple-100 border border-purple-600 font-medium text-sm py-3 mt-10 px-4 rounded-3xl  w-full text-center"
                      >
                        Download
                      </Link>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
      </section>
    </>
  )
}

export function Head() {
  return (
    <meta
      httpEquiv="Content-Security-Policy"
      content="upgrade-insecure-requests"
    />
  )
}
